/** @jsx jsx */
import { useState } from "react";
// import { graphql } from "gatsby";
// import styled from "@emotion/styled";
import { jsx, Grid, Box, Heading, Text } from "theme-ui";
import ResizeObserver from "react-resize-observer";

import Layout from "../components/layout";
import BreadCrumbs from "../components/breadcrumbs";

const Mat = ({ pageContext }) => {
  const [width, setWidth] = useState(null);

  const {
    data: { name, descr, cennik, images },
  } = pageContext;

  const seo = {
    metaTitle: name,
    // metaDescription: description,
    // shareImage: images[0].url,
    // article: true,
  };

  const { crumbs } = pageContext?.breadcrumb || {};

  const colWidth = "125px";

  return (
    <Layout seo={seo}>
      <ResizeObserver
        onResize={rect => {
          setWidth(rect.width);
        }}
      />
      {/* <Nav showGoBack={false} /> */}

      <Box sx={{ minHeight: "87vh" }}>
        <Grid gap={2} columns={[1, 1, 1, 1, 2, "2fr 3fr"]}>
          <Box px={2}>
            <BreadCrumbs data={crumbs} />
            <Box m="1.5rem">
              <img
                sx={{ width: [300, 500, 600], m: "auto" }}
                src={images.hero}
              />
            </Box>
          </Box>

          <Box px={2} mt="3rem" mx={["auto", "auto", "auto", null]}>
            <Heading my="1.5rem">{name}</Heading>
            <Text
              mr="3rem"
              sx={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{ __html: descr }}
            />
            {/* specification */}
            {/* TODO: buttons to ask for similar offer and share on FB, instagram */}

            <Grid width={[51]} mt="1.5rem" ml="-5px">
              {images.qualities &&
                images.qualities.map(url => (
                  <img sx={{ width: "51px", mx: "5px" }} src={url} />
                ))}
            </Grid>

            <Box mt="1.5rem" sx={{ justifyContent: "center", display: "flex" }}>
              <table>
                <tr>
                  {cennik.header
                    .filter((h, i) => i === 0)
                    .map(h => (
                      <th sx={{ width: colWidth }}>{h}</th>
                    ))}
                </tr>

                {cennik.rows.map(r => {
                  return (
                    <tr>
                      {r
                        .filter((h, i) => i === 0)
                        .map(h => (
                          <td sx={{ width: colWidth, textAlign: "center" }}>
                            {h}
                          </td>
                        ))}
                    </tr>
                  );
                })}
              </table>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Mat;

// export const query = graphql`
//   query MatQuery($name: String!) {
//     drevocalJson(name: { eq: $name }) {
//       id
//       name
//       descr
//       url
//       # cennik
//       images {
//         hero
//         qualities
//         taxonomy
//       }
//     }
//   }
// `;
